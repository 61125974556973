import classNames from 'classnames'
import { ReactNode, Children } from 'react'

export type Props = {
  message?: string
  className?: string
  children?: ReactNode
}

const InputErrorText = (props: Props) => {
  const { message, children, className } = props
  if (!message && !Children.count(children)) return null
  return (
    <div
      className={classNames(
        'mt-2 flex flex-row items-center text-[10px] font-normal normal-case text-error lg:text-[12px]',
        className,
      )}
    >
      {message}
      {children}
    </div>
  )
}

export default InputErrorText
