import { XMarkIcon } from '@heroicons/react/24/outline'

type CloseButtonProps = {
  buttonClassname?: string
  iconClassName?: string
  onClose: () => void
  type?: 'button' | 'submit' | 'reset'
}

const CloseButton = (props: CloseButtonProps) => {
  const { buttonClassname, iconClassName = 'size-5', onClose, type = 'button' } = props
  return (
    <button className={buttonClassname} onClick={onClose} type={type}>
      <XMarkIcon className={iconClassName} />
    </button>
  )
}
export default CloseButton
