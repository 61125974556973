import cn from 'classnames'
import Link from 'next/link'
import { MouseEventHandler, ReactNode, RefObject, useCallback } from 'react'
import SpinnerIcon from '../../icons/SpinnerIcon'

type ButtonWrapperProps = {
  internalHref?: string
  externalHref?: string
  children: ReactNode
}

const ButtonWrapper = (props: ButtonWrapperProps) => {
  const { internalHref, children, externalHref } = props
  if (internalHref) {
    return (
      <Link passHref href={internalHref} legacyBehavior>
        {children}
      </Link>
    )
  }

  if (externalHref) {
    return (
      <a href={internalHref} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }

  return <>{children}</>
}

type ControlButtonProps = {
  onClick?: () => void
  name?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  wrapperClass?: string
  contentClass?: string
  internalHref?: string
  externalHref?: string
  disabled?: boolean
  loading?: boolean
  children?: ReactNode
  buttonRef?: RefObject<HTMLButtonElement>
  form?: string
  loadingClassName?: string
  showIconForMobile?: boolean
}

const Button = (props: ControlButtonProps) => {
  const { onClick, loadingClassName, showIconForMobile } = props
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (!onClick) return
      // prevent the event from bubbling up the DOM tree if the onClick is provided
      e.preventDefault()
      e.stopPropagation()
      onClick()
    },
    [onClick],
  )

  return (
    <ButtonWrapper internalHref={props.internalHref} externalHref={props.externalHref}>
      <button
        className={cn(
          'flex items-center justify-center rounded-full p-3 transition-all',
          props.wrapperClass,
          !showIconForMobile && 'min-w-[100px]',
          showIconForMobile && 'w-fit lg:min-w-[100px]',
          { 'cursor-not-allowed opacity-40 hover:shadow-none': props.disabled || props.loading },
        )}
        type={props.type || 'button'} // default to be button to prevent submitting the form when click a button
        onClick={handleClick}
        disabled={props.disabled}
        ref={props.buttonRef}
        form={props.form}
      >
        {props.children}
        {props.name != null && (
          <span
            className={cn(
              'flex h-full items-center justify-center gap-2 text-xs uppercase tracking-wider',
              props.contentClass,
            )}
            style={{ lineHeight: '100%' }}
          >
            {props.loading && <SpinnerIcon className={loadingClassName} />}
            <span className={cn({ 'hidden lg:block': showIconForMobile })}>{props.name}</span>
          </span>
        )}
      </button>
    </ButtonWrapper>
  )
}

export default Button
